<template>
  <div class="container">
    <div class="card border-0 shadow-none">
      <div class="card-body">
        <div class="row mb-4">
          <div class="col">
            <h5 class="fw-bold">Add user role</h5>
            <p class="alert alert-success">
              Add user role, set a name for the role and select the permissions
              relevant to this role
            </p>
          </div>
          <div class="col-auto ms-auto">
            <router-link class="btn btn-success btn-sm text-white" to="/roles"
              ><i class="far fa-arrow-left me-2"></i>Back</router-link
            >
          </div>
        </div>
        <div class="row">
          <div class="col">
            <form @submit.prevent>
              <div class="row">
                <div class="col">
                  <input
                    type="text"
                    placeholder="Role name"
                    class="form-control"
                  />
                </div>
                <div class="col">
                  <ul>
                    <li v-for="p in permissions" :key="p.id">
                      {{ p.name }}
                    </li>
                  </ul>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      permissions: [],
    };
  },
  methods: {
    fetchPermissions() {
      this.$axios
        .get(process.env.VUE_APP_API_URL + "/permissions")
        .then(({ data }) => {
          this.permissions = data;
        });
    },
  },
  mounted() {
    this.fetchPermissions();
  },
};
</script>

<style>
</style>